import React, { Fragment } from 'react';

import MobileCompanyLogo from 'components/Shared/Merchant/CompanyLogo/MobileCompanyLogo';
import { breakpoints, colors, fontSize } from 'styles/cp';
import { FeatureFlags } from 'types/FeatureFlags';
import { IXP } from 'types/IXP';

export interface Props {
  companyLogoUrl: string;
  companyName: string;
  isUnPayable?: boolean;
  featureFlags?: FeatureFlags;
  ixp?: IXP;
}

const MobileInvoiceHeader: React.FC<Props> = ({ companyLogoUrl, companyName, isUnPayable }) => {
  return (
    <Fragment>
      <div className={isUnPayable ? 'unpayable-style' : 'regular-style'}>
        <MobileCompanyLogo companyLogoUrl={companyLogoUrl} />
        <h2 className="company-name" data-cy="main-card_company-name">
          {companyName}
        </h2>
      </div>
      <style jsx>{`
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        .unpayable-style {
          padding: 15px 60px 0;

          @media screen and (max-width: ${breakpoints.md}) {
            padding: 20px 20px 0;
          }

          .company-name {
            font-size: ${fontSize.xxl};
            color: ${colors.darkGray};
            margin-top: 70px;
            margin-bottom: 5px;
            text-align: center;
            font-family: AvenirNextforINTUIT-Bold;

            @media screen and (max-width: ${breakpoints.md}) {
              font-size: ${fontSize.lg};
              margin: 15px 0;
            }
          }
        }
        .regular-style {
          padding: 15px 66px 0;
          max-width: 882px;

          @media screen and (max-width: ${breakpoints.md}) {
            padding: 20px 20px 0;
            &.bottom {
              padding: 0 20px;
            }
          }
        }
        .company-name {
          display: none;
          @media screen and (max-width: ${breakpoints.md}) {
            display: block;
            color: ${colors.darkGray};
            text-align: center;
            font-family: AvenirNextforINTUIT-Bold;
            font-size: 20px;
            margin-top: 15px;
          }
        }
      `}</style>
    </Fragment>
  );
};

export default MobileInvoiceHeader;
