import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import Card from 'components/Shared/Card/Card';
import { saleSelectors } from 'store/sale/selectors';
import { breakpoints, colors, fontSize } from 'styles/cp';
import { Sale } from 'types/Sale';

type PaymentInstructionsProps = {
  paymentDetailsMessage?: string;
};

const PaymentInstructions = ({ paymentDetailsMessage }: PaymentInstructionsProps) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const maybeTruncate = () => {
    if (isTruncated && paymentDetailsMessage) {
      return paymentDetailsMessage.length > 84
        ? paymentDetailsMessage.substring(0, 84) + '...'
        : paymentDetailsMessage;
    }
    return paymentDetailsMessage;
  };

  if (!paymentDetailsMessage || typeof paymentDetailsMessage !== 'string') {
    return <Fragment />;
  }

  return (
    <Fragment>
      <style jsx>{`
        .p-i-wrapper {
          @media (max-width: ${breakpoints.md}) {
            padding: 0 16px 40px 16px;
          }
        }
        .w {
          margin: 0 4px 4px 4px;

          .header {
            padding: 13px 16px 4px 16px;
            display: block;
            width: 100%;
            font-size: ${fontSize.sm};
            font-weight: bold;
          }

          .msg {
            padding: 0px 16px 16px 16px;
            display: block;
            width: 100%;
            font-size: ${fontSize.xs};
          }

          .truncate {
            padding-top: ${isTruncated ? '0' : '18px'};
            background-color: inherit;
            border: none;
            color: ${colors.blue};
            cursor: pointer;
            display: block;
            padding-left: 1px;
          }

          .truncate:active,
          truncate:hover {
            outline: none;
          }
        }
      `}</style>
      <div className="p-i-wrapper">
        <Card>
          <div className="w">
            <div className="header">
              <FormattedMessage
                id="MERCHANT_PAYMENT_INSTRUCTIONS"
                defaultMessage="Payment instructions"
              />
            </div>
            <div className="msg">
              {maybeTruncate()}
              {paymentDetailsMessage.length > 84 && (
                <button className="truncate" onClick={() => setIsTruncated(!isTruncated)}>
                  {isTruncated ? 'See more' : 'See less'}
                </button>
              )}
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

function mapStateToProps({ sale }: { sale: Sale }) {
  return {
    paymentDetailsMessage: saleSelectors.paymentDetailsMessageSelector(sale),
  };
}

export default connect(mapStateToProps, {})(PaymentInstructions);
