import React, { Fragment } from 'react';

import Button from 'components/Shared/Button/Button';
import { breakpoints } from 'styles/cp';

export const PbbContinueButton = () => {
  return (
    <Fragment>
      <div className="pay-button">
        <Button
          disabled={false}
          buttonType="primary"
          className={'pay'}
          size="standard"
          width="100%"
          aria-label="Pay Button"
        >
          Continue
        </Button>
      </div>
      <style jsx>{`
        .pay-button {
          width: 100%;

          :global(.hidden-mobile) {
            @media screen and (max-width: ${breakpoints.md}) {
              display: none;
            }
          }
        }
      `}</style>
    </Fragment>
  );
};
