import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

import { colors } from 'styles/cp';

const WarningAlertIcon: React.FC<IconBasePropsWithColor> = ({
  width = 20,
  height = 20,
  color = colors.warn,
  exclamationColor = colors.gray,
}) => {
  return (
    <svg
      data-testid="warning-alert-icon"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.6665" y="1.66675" width="16.6667" height="16.6667" rx="8.33333" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99985 11.6667C9.53901 11.6667 9.16651 11.2942 9.16651 10.8334V6.66671C9.16651 6.20587 9.53901 5.83337 9.99985 5.83337C10.4607 5.83337 10.8332 6.20587 10.8332 6.66671V10.8334C10.8332 11.2942 10.4607 11.6667 9.99985 11.6667ZM10.8332 13.3334C10.8332 12.8734 10.4607 12.5 9.99984 12.5C9.539 12.5 9.1665 12.8734 9.1665 13.3334C9.1665 13.7942 9.539 14.1667 9.99984 14.1667C10.4607 14.1667 10.8332 13.7942 10.8332 13.3334Z"
        fill={exclamationColor}
      />
    </svg>
  );
};

export default WarningAlertIcon;
