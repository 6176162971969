import { isGrowthPayPalCheckoutEnabled } from 'store/ixp/selector';
import { FeatureFlags } from 'types/FeatureFlags';
import { IXP } from 'types/IXP';
import { TXN_MAP } from 'types/constants';

export type PayPalCheckoutExperiment = {
  isPPCheckoutExperiment: boolean;
  isPPExperimentTreatmentA: boolean;
  isPPExperimentTreatmentB: boolean;
};

export const checkPayPalCheckoutEnabled = (
  isPayEnabled: boolean,
  ixp: IXP,
  featureFlags: FeatureFlags,
  paymentMethodsOrder: any[],
  sale: any,
  achOnlineConvenienceFeeEnabled: boolean
): PayPalCheckoutExperiment => {
  const treatmentKeyA = 'IXP2_T_687932';
  const treatmentKeyB = 'IXP2_T_687933';
  let isPPExperimentTreatmentA = false;
  let isPPExperimentTreatmentB = false;
  let isValidInvoice = false;

  //check if payment and paypal is enabled
  const isPayPalEnabled = paymentMethodsOrder.some((value) => {
    return value === 'paypal_ppaam' || value === 'venmo';
  });

  const isInvoice = sale.type === TXN_MAP.TYPES.INVOICE;
  const isMAIPInvoice = achOnlineConvenienceFeeEnabled;
  isValidInvoice = isPayEnabled && isPayPalEnabled && isInvoice && !isMAIPInvoice;

  if (isValidInvoice) {
    const treatmentKey = isGrowthPayPalCheckoutEnabled({
      ixp,
      featureFlags,
    });
    if (treatmentKey && treatmentKey === treatmentKeyA) {
      isPPExperimentTreatmentA = true;
    }
    if (treatmentKey && treatmentKey === treatmentKeyB) {
      isPPExperimentTreatmentB = true;
    }
  }
  return {
    isPPCheckoutExperiment: isPPExperimentTreatmentA || isPPExperimentTreatmentB,
    isPPExperimentTreatmentA,
    isPPExperimentTreatmentB,
  };
};
