import Link from 'next/link';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import SegmentIO from 'reporting/SegmentIO';
import { breakpoints, colors, fontSize } from 'styles/cp';
import { COUNTRY } from 'types/constants';

export const PRIVACY_LINK = 'https://security.intuit.com/index.php/privacy';
export const TOS_LINK = '/termsOfService';

export interface MainFooterProps {
  companyLocale: String;
  isPayable: Boolean;
  bottomPadding?: number;
  topPadding?: number;
  redesign?: boolean;
}

const MainFooter: React.FC<MainFooterProps> = ({
  redesign,
  companyLocale,
  isPayable,
  bottomPadding = 0,
  topPadding = 8,
}) => {
  const fullYear = new Date().getFullYear();
  const country = companyLocale.split('_')[1];

  const maybeShowPrivacyLink = () => {
    if (!isPayable) {
      const onClick = () => {
        SegmentIO.clickPrivacy();
        SegmentIO.transactionEngaged({
          activity_type: 'optional_actions',
          ui_object: 'link',
          ui_action: 'clicked',
          ui_access_point: 'transaction_flow',
          ui_object_detail: 'privacy_statement',
        });
        window.open(PRIVACY_LINK, '_blank', 'noopener,noreferrer');
      };

      return (
        <span className="cp-link">
          &nbsp;
          <a id="main-footer-privacy" onClick={onClick} href={PRIVACY_LINK}>
            <FormattedMessage id="INVOICE_VIEW_PAGE_FOOTER_PRIVACY" defaultMessage="Privacy" />
          </a>
        </span>
      );
    }
    return null;
  };

  const maybeShowTOSLink = () => {
    let _show = false;

    if (country === COUNTRY.US || country === COUNTRY.CA) {
      _show = !isPayable;
    }
    if (_show) {
      const onClick = () => {
        SegmentIO.clickTermsOfService();
        SegmentIO.transactionEngaged({
          activity_type: 'optional_actions',
          ui_object: 'link',
          ui_action: 'clicked',
          ui_access_point: 'transaction_flow',
          ui_object_detail: 'terms_of_service',
        });
        window.open(TOS_LINK, '_blank', 'noopener,noreferrer');
      };

      return (
        <span className="cp-link">
          <span> | </span>
          <Link href={TOS_LINK} as="/payor-terms-of-service" legacyBehavior>
            <a className="a" onClick={onClick} href="#">
              <FormattedMessage
                id="INVOICE_VIEW_PAGE_FOOTER_TOS"
                defaultMessage="Terms of service"
              />
            </a>
          </Link>
        </span>
      );
    }
    return null;
  };

  return (
    <Fragment>
      <div className="main-footer">
        <FormattedMessage
          id="INVOICE_ALL_RIGHT_RESERVED"
          values={{ '0': fullYear }}
          defaultMessage={`© ${fullYear} Intuit Inc. All right reserved`}
        />
        {maybeShowPrivacyLink()}
        {maybeShowTOSLink()}
      </div>

      <style jsx>{`
        .main-footer {
          font-family: AvenirNextforINTUIT-Regular;
          font-size: ${redesign ? fontSize.xxxs : fontSize.xxs};
          text-align: center;
          color: ${colors.ghostGray};
          padding: ${topPadding}px 8px ${bottomPadding}px;

          @media (max-width: ${breakpoints.md}) {
          }

          a {
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      `}</style>
    </Fragment>
  );
};

export default MainFooter;
