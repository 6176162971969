import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import PaymentInstructions from 'components/Core/SidePanel/PaymentInstructions/PaymentInstructions';
import TrustSection from 'components/Core/TrustSection/TrustSection';
import ContactMerchantCard from 'components/Shared/Contact/MerchantCard/ContactMerchantCard';
import Separator from 'components/Shared/Separator/Separator';
import { saleSelectors } from 'store/sale/selectors';
import { breakpoints } from 'styles/cp';
import { FeatureFlags } from 'types/FeatureFlags';
import { IXP } from 'types/IXP';
import { Sale } from 'types/Sale';

export interface Props {
  children: React.ReactNode | string | null;
  config: any;
  companyDetails: {
    companyAddress: Record<string, string>;
    companyEmail: string;
    companyPhone: string;
    companyWebAddr: string;
    number: string;
  };
  isPaymentInstruction: boolean;
  sale: Sale;
  ixp: IXP;
  featureFlags: FeatureFlags;
  paymentDetailsMessage: string;
}

const SidePanelWrapper: React.FC<Props> = ({
  children,
  config,
  companyDetails,
  isPaymentInstruction,
  paymentDetailsMessage,
}) => {
  const { companyAddress, companyEmail, companyPhone, companyWebAddr, number } = companyDetails;
  return (
    <Fragment>
      <aside className="side-panel">
        {children}

        <Separator height={15} />

        <ContactMerchantCard
          companyAddress={companyAddress}
          companyEmail={companyEmail}
          companyPhone={companyPhone}
          companyWebAddr={companyWebAddr}
          number={number}
        />

        <Separator height={15} />

        {isPaymentInstruction && <PaymentInstructions />}

        {/* <Separator height={15} /> */}

        {paymentDetailsMessage && <Separator height={15} />}

        <TrustSection cdn={config.endpoints.cdn} />
      </aside>
      <style jsx>{`
        .side-panel {
          width: 35%;
          padding-left: 20px;
          padding-top: 15px;
          float: right;
          @media screen and (max-width: ${breakpoints.md}) {
            display: none;
          }
        }
      `}</style>
    </Fragment>
  );
};
function mapStateToProps(store: any) {
  const { sale, config, ixp, featureFlags } = store;
  return {
    paymentDetailsMessage: saleSelectors.paymentDetailsMessageSelector(sale),
    ixp,
    featureFlags,
    sale,
    config,
  };
}

// @ts-ignore
export default connect(mapStateToProps, {})(SidePanelWrapper);
